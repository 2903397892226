@font-face {
	font-family: Charlie;
	font-weight: 900;
	font-style: normal;
	src: url(fonts/CharlieStd-Black.svg) format("svg"),
	url(fonts/CharlieStd-Black.otf) format("otf"),
	url(fonts/CharlieStd-Black.ttf) format("ttf"),
	url(fonts/CharlieStd-Black.eot) format("eot")
}

@font-face {
	font-family: Charlie;
	font-weight: 500;
	font-style: normal;
	src: url(fonts/Charlie-Std-Medium.svg) format("svg"),
	url(fonts/Charlie-Std-Medium.woff) format("woff"),
	url(fonts/Charlie-Std-Medium.ttf) format("ttf"),
	url(fonts/Charlie-Std-Medium.eot) format("eot")
}

@font-face {
	font-family: Charlie;
	font-weight: 400;
	font-style: normal;
	src: url(fonts/Charlie-Std-Regular.svg) format("svg"),
	url(fonts/Charlie-Std-Regular.woff) format("woff"),
	url(fonts/Charlie-Std-Regular.ttf) format("ttf"),
	url(fonts/Charlie-Std-Regular.eot) format("eot")
}

@font-face {
	font-family: Charlie;
	font-weight: 700;
	font-style: normal;
	src: url(fonts/Charlie-Std-SemiBold.svg) format("svg"),
	url(fonts/Charlie-Std-SemiBold.woff) format("woff"),
	url(fonts/Charlie-Std-SemiBold.ttf) format("ttf"),
	url(fonts/Charlie-Std-SemiBold.eot) format("eot")
}

@font-face {
	font-family: Charlie;
	font-weight: 700;
	font-style: italic;
	src: url(fonts/Charlie-Std-SemiBold-Italic.svg) format("svg"),
	url(fonts/Charlie-Std-SemiBold-Italic.woff) format("woff"),
	url(fonts/Charlie-Std-SemiBold-Italic.ttf) format("ttf"),
	url(fonts/Charlie-Std-SemiBold-Italic.eot) format("eotc")
}
